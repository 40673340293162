<template>
  <div class="viewDialog">
    <BaseDialog
      :title="!ruleForm.id ? '新增患者档案' : '修改患者档案'"
      :isshow="visible"
      @handleShow="
        (val) => {
          visible = val
        }
      "
      width="85%"
      height="auto"
    >
      <div class="cont flex-start">
        <div class="cont-left">
          <div class="upload">
            <el-upload
              class="avatar-uploader"
              action=""
              :http-request="handleRequest"
              :show-file-list="false"
            >
              <img
                v-if="ruleForm.avatar"
                :src="ruleForm.avatar"
                class="avatar"
              />
              <div v-else class="avatar-uploader-icon">
                <div class="tit">上传头像</div>
              </div>
            </el-upload>
          </div>
          <el-form
            ref="ruleForm1Ref"
            :model="ruleForm"
            :rules="infoRule"
            label-position="top"
            class="demo-ruleForm"
          >
            <el-form-item label="医生：">
              <el-select
                v-model="ruleForm.duty_doctor_id"
                @change="(val) => selectChang(val, 'duty_doctor')"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in dictList.duty_doctor"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="责任护士：">
              <el-select
                v-model="ruleForm.duty_nurse_id"
                @change="(val) => selectChang(val, 'duty_nurse')"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in dictList.duty_nurse"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="院区：" prop="hospital_area_id">
              <el-select
                v-model="ruleForm.hospital_area_id"
                @change="(val) => selectChang(val, 'hospital_area')"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in dictList.hospital_area"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="接诊区：" prop="name">
              <el-select
                v-model="ruleForm.dict_hospital_area"
                clearable
                placeholder="请选择"
              >
                <el-option label="主院区" value="0"></el-option>
              </el-select>
            </el-form-item> -->
          </el-form>
        </div>
        <div class="cont-main nowarp col999">
          <el-scrollbar>
            <div class="padRight-20">
              <div class="userItem">
                <div class="user-tit">his绑定</div>
                <el-form
                  :model="ruleForm"
                  label-width="116px"
                  label-position="right"
                  :inline="true"
                  class="demo-ruleForm col-333 lable-100"
                >
                  <el-form-item label="搜索：" class="his">
                    <el-select
                      v-model="hisSraech"
                      filterable
                      remote
                      reserve-keyword
                      placeholder="请输入"
                      :remote-method="remoteMethod"
                      :loading="loading"
                      @change="hisChange"
                    >
                      <el-option
                        v-for="(item, index) in hisList"
                        :key="index"
                        :label="item.name"
                        :value="item.his_id"
                      >
                        <span>{{ item.name }}</span>
                        <span style="margin: 0 12px">{{ item.card_no }}</span>
                        <span>{{ item.mobile }}</span>
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <br />
                  <el-form-item class="hisid" label="hisID：">
                    <el-input
                      v-model="ruleForm.hospital_his_id"
                      disabled
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="诊疗卡号：">
                    <el-input
                      v-model="ruleForm.consultation_no"
                      disabled
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    class="unbinding"
                    v-if="ruleForm.hospital_his_id || ruleForm.consultation_no"
                  >
                    <el-button
                      @click="Unbinding"
                      class="color-determine"
                      type="primary"
                    >
                      解绑
                    </el-button>
                  </el-form-item>
                </el-form>
              </div>
              <div class="userItem">
                <div class="user-tit">个人信息</div>
                <el-form
                  ref="ruleForm2Ref"
                  :model="ruleForm"
                  :rules="infoRule"
                  label-width="116px"
                  label-position="right"
                  :inline="true"
                  class="demo-ruleForm col-333 lable-100"
                >
                  <el-form-item label="姓名：" prop="name">
                    <el-input
                      v-model="ruleForm.name"
                      placeholder="请输入姓名"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="证件类型：">
                    <el-select
                      v-model="ruleForm.dict_cert"
                      placeholder="请选择证件类型"
                    >
                      <el-option
                        v-for="item in dictList[162000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="证件号码：" prop="cert_no">
                    <el-input
                      @blur="certNoBlur"
                      v-model="ruleForm.cert_no"
                      placeholder="请输入证件号码"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="性别：" prop="dict_sex">
                    <el-select
                      v-model="ruleForm.dict_sex"
                      placeholder="请选择性别"
                    >
                      <el-option
                        v-for="item in dictList[101000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="出生日期：" prop="birth_date">
                    <el-date-picker
                      v-model="ruleForm.birth_date"
                      type="date"
                      value-format="YYYY-MM-DD"
                      placeholder="请选择出生日期"
                    ></el-date-picker>
                  </el-form-item>
                  <el-form-item label="手机号码：" prop="mobile_phone">
                    <el-input
                      v-model="ruleForm.mobile_phone"
                      placeholder="请输入手机号码"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="民族：">
                    <el-select
                      v-model="ruleForm.dict_nation"
                      placeholder="请选择民族"
                    >
                      <el-option
                        v-for="item in dictList[102000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="医保类型：">
                    <el-select
                      v-model="ruleForm.dict_payment_type"
                      placeholder="请选择医保类型"
                    >
                      <el-option
                        v-for="item in dictList[103000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="透析频次：">
                    <el-select
                      v-model="ruleForm.dict_dialysis_cycle"
                      placeholder="请选择透析频次"
                    >
                      <el-option
                        v-for="item in dictList[117000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="年龄：">
                    <el-input
                      v-model="ruleForm.age"
                      placeholder="请输入年龄"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="家属姓名：">
                    <el-input
                      v-model="ruleForm.contact_person_name"
                      placeholder="请输入家属姓名"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="家属电话：">
                    <el-input
                      v-model="ruleForm.contact_person_mobile"
                      placeholder="请输入家属电话"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="身高：">
                    <el-input
                      v-model="ruleForm.height"
                      placeholder="请输入身高"
                    >
                      <template #append>cm</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="婚姻状况：">
                    <el-select
                      v-model="ruleForm.dict_marry"
                      placeholder="请选择婚姻状况"
                    >
                      <el-option
                        v-for="item in dictList[315000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label=""></el-form-item>
                  <el-form-item label="住址：">
                    <SelectCascader
                      ref="selectCascaderRef"
                      :value="ruleForm.cityData"
                      @input="inputChang"
                    ></SelectCascader>
                  </el-form-item>
                  <el-form-item class="addr" label="详细地址：">
                    <el-input
                      v-model="ruleForm.address"
                      placeholder="请输入详细地址"
                    ></el-input>
                  </el-form-item>
                </el-form>
              </div>
              <div class="userItem">
                <div class="user-tit">病历信息</div>
                <el-form
                  ref="ruleForm3Ref"
                  :model="ruleForm"
                  :rules="infoRule"
                  label-width="116px"
                  label-position="right"
                  :inline="true"
                  class="demo-ruleForm col-333 lable-100"
                >
                  <el-form-item label="病案号：" prop="case_no">
                    <el-input
                      v-model="ruleForm.case_no"
                      placeholder="请输入病案号"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="病历编码：">
                    <el-input
                      v-model="ruleForm.medical_no"
                      placeholder="请输入病历编码"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="诊疗卡号：">
                    <el-input
                      v-model="ruleForm.consultation_no"
                      placeholder="请输入诊疗卡号"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="透析卡号：">
                    <el-input
                      v-model="ruleForm.dialysis_card_no"
                      placeholder="请输入透析卡号"
                    ></el-input>
                  </el-form-item>
                  <!-- <el-form-item label="门诊号：">
                    <el-input
                      v-model="ruleForm.menzhen_no"
                      placeholder="请输入门诊号"
                    ></el-input>
                  </el-form-item> -->
                  <el-form-item label="住院号：">
                    <el-input
                      v-model="ruleForm.hospital_no"
                      placeholder="请输入住院号"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="医保号：">
                    <el-input
                      v-model="ruleForm.payment_no"
                      placeholder="请输入医保号"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="病人来源：" prop="dict_patient_source">
                    <el-select
                      v-model="ruleForm.dict_patient_source"
                      placeholder="请选择病人来源"
                    >
                      <el-option
                        v-for="item in dictList[105000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="来源科室：">
                    <el-select
                      v-model="ruleForm.dept"
                      placeholder="请选择来源科室"
                    >
                      <el-option
                        v-for="item in dictList[156000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="病区：">
                    <el-input
                      v-model="ruleForm.hospital_area"
                      placeholder="请输入病区"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="床位号：">
                    <el-input
                      v-model="ruleForm.hospital_bed_no"
                      placeholder="请输入床位号"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="血型：">
                    <el-select
                      v-model="ruleForm.dict_blood_type"
                      placeholder="请选择血型"
                    >
                      <el-option
                        v-for="item in dictList[104000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="rh血型：">
                    <el-select
                      v-model="ruleForm.dict_rh_blood_type"
                      placeholder="请选择rh血型"
                    >
                      <el-option
                        v-for="item in dictList[109000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="入室时间：" prop="dialysis_center_time">
                    <el-date-picker
                      v-model="ruleForm.dialysis_center_time"
                      type="date"
                      value-format="YYYY-MM-DD"
                      placeholder="请选择入室时间"
                    ></el-date-picker>
                  </el-form-item>
                  <el-form-item label="中心患者：" prop="is_center">
                    <el-select
                      v-model="ruleForm.is_center"
                      placeholder="请选择中心患者"
                    >
                      <el-option label="是" :value="1"></el-option>
                      <el-option label="否" :value="0"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="透析类型：" prop="dict_dialysis_type">
                    <el-select
                      v-model="ruleForm.dict_dialysis_type"
                      placeholder="请选择透析类型"
                    >
                      <el-option
                        v-for="item in dictList[210000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="首透方式：">
                    <el-select
                      v-model="ruleForm.dict_first_dialysis_method"
                      placeholder="请选择首透方式"
                    >
                      <el-option
                        v-for="item in dictList[106000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="首透时间：">
                    <el-date-picker
                      v-model="ruleForm.first_hd_time"
                      type="date"
                      value-format="YYYY-MM-DD"
                      placeholder="请选择首透时间"
                    ></el-date-picker>
                  </el-form-item>
                  <el-form-item label="透析次数：">
                    <el-input
                      v-model="ruleForm.dialysis_count"
                      placeholder="请输入透析次数"
                    ></el-input>
                  </el-form-item>
                  <!-- <el-form-item label="HIS患者ID：">
                    <el-input
                      v-model="ruleForm.his_patient_id"
                      placeholder="请输入HIS患者ID"
                    ></el-input>
                  </el-form-item> -->
                  <el-form-item label="发病原因：">
                    <el-select
                      v-model="ruleForm.dict_ill_reason"
                      placeholder="请选择发病原因"
                    >
                      <el-option
                        v-for="item in dictList[390000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-form>
              </div>
              <div class="userItem collapse">
                <el-collapse v-model="activeCollNames" accordion>
                  <el-collapse-item name="1" disabled>
                    <template #title>
                      <div class="user-tit">感染状况</div>
                      <el-switch
                        @change="InfectedChange"
                        :width="48"
                        v-model="ruleForm.is_open_ganran"
                      />
                    </template>
                    <div class="infected flex-start">
                      <div
                        class="infected-item flex-start"
                        v-for="item in dictList[108000000]"
                        :key="item.code"
                      >
                        <div class="infected-type">
                          <el-checkbox
                            v-model="item.checked"
                            @change="
                              (val) => {
                                infectedTypeRadio(val, item)
                              }
                            "
                            label=""
                          ></el-checkbox>
                          <span
                            style="top: 40px; color: #fff; position: relative"
                            >{{ item.name }}</span
                          >
                        </div>
                        <div class="infected-cont">
                          <el-radio-group
                            v-model="item.infect_type"
                            @change="
                              (val) => {
                                infectedTypeChange(val, item)
                              }
                            "
                          >
                            <el-radio :label="0">原有</el-radio>
                            <el-radio :label="1">
                              新增：
                              <el-date-picker
                                v-model="item.date1"
                                :disabled="item.infect_type != 1"
                                type="date"
                                value-format="YYYY-MM-DD"
                                placeholder="请选择新增时间"
                              ></el-date-picker>
                            </el-radio>
                            <el-radio :label="2">
                              转阴：
                              <el-date-picker
                                v-model="item.date2"
                                type="date"
                                :disabled="item.infect_type != 2"
                                value-format="YYYY-MM-DD"
                                placeholder="请选择转阴时间"
                              ></el-date-picker>
                            </el-radio>
                          </el-radio-group>
                        </div>
                      </div>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </div>
              <div class="userItem">
                <div class="user-tit">选择患者标签</div>
                <div class="parient-tag">
                  <el-tag
                    :class="item.active ? 'active' : ''"
                    v-for="item in dictList[323000000]"
                    :key="item.id"
                    @click="tagBut(item)"
                  >
                    {{ item.name }}
                  </el-tag>
                </div>
              </div>
            </div>
          </el-scrollbar>
        </div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button
            class="color-cancel"
            type="primary"
            @click="visible = false"
          >
            取消
          </el-button>
          <el-button
            @click="savePatient"
            :loading="saveLoad"
            class="color-determine"
            type="primary"
          >
            确定
          </el-button>
        </div>
      </template>
    </BaseDialog>
  </div>
</template>
<script>
import { reactive, toRefs, nextTick } from 'vue'
import service from '@/utils/request'
import { gitDictList, TimeYear, IdCard } from '@/utils/tool'
import { ElMessage, ElMessageBox } from 'element-plus'
import validator from '@/utils/validator'
import BaseDialog from '@/components/Dialog/index.vue'
import SelectCascader from '@/components/select/SelectCascader.vue'
import comm from '@/utils/comm'
let port = location.protocol
        let ip = comm.cesuUrl
        let requestUrl = `${port}//${ip}/`
export default {
  name: 'patentList',
  components: {
    BaseDialog,
    SelectCascader,
  },
  emits: ['refreshDataList'],
  setup(props, ctx) {
    const validateCert = (rule, value, callback) => {
      if (state.ruleForm.dict_cert === '162110000') {
        let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
        if (!reg.test(value)) {
          callback(new Error())
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    const validateCaseNo = async (rule, value, callback) => {
      if (state.ruleForm.id) {
        callback()
      } else {
        let res = await service.post(
          '/api/patient/check_patient_case_no_valid',
          {
            case_no: value,
          }
        )
        if (res.data.valid === 1) {
          callback()
        } else {
          callback(new Error())
        }
      }
    }
    const state = reactive({
      ruleForm: {
        cert_no: '',
        cityData: [],
        patient_infect_list: [],
      },
      visible: false,
      saveLoad: false,
      dictList: {},
      city: [],
      ruleForm1Ref: null,
      ruleForm2Ref: null,
      ruleForm3Ref: null,
      selectCascaderRef: null,
      vals: '',
      activeCollNames: '',
      loading: false,
      hisList: [],
      hisSraech: '',
      infoRule: {
        hospital_area_id: [
          { required: true, trigger: 'blur', message: '请选择院区' },
        ],
        name: [{ required: true, trigger: 'blur', message: '请输入姓名' }],
        cert_no: [
          { required: true, trigger: 'blur', message: '请输入证件号码' },
          {
            validator: validateCert,
            trigger: 'blur',
            message: '证件号码格式不正确',
          },
        ],
        dict_sex: [{ required: true, trigger: 'blur', message: '请选择性别' }],
        birth_date: [
          {
            type: 'date',
            required: true,
            trigger: 'blur',
            message: '请选择出生日期',
          },
        ],
        mobile_phone: [
          { required: true, trigger: 'blur', message: '请输入手机号码' },
          {
            validator: validator.phone,
            trigger: 'blur',
            message: '手机号格式不正确',
          },
        ],
        case_no: [
          { required: true, trigger: 'blur', message: '请输入病案号' },
          {
            validator: validateCaseNo,
            trigger: 'blur',
            message: '病案号已被使用',
          },
        ],
        dict_patient_source: [
          { required: true, trigger: 'blur', message: '请选择病人来源' },
        ],
        dialysis_center_time: [
          { required: true, trigger: 'blur', message: '请选择入室时间' },
        ],
        is_center: [
          { required: true, trigger: 'blur', message: '请选择中心患者' },
        ],
        dict_dialysis_type: [
          { required: true, trigger: 'blur', message: '请选择透析类型' },
        ],
      },
    })

    const certNoBlur = () => {
      let val = state.ruleForm.cert_no
      let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
      if (!reg.test(val)) return
      state.ruleForm.dict_sex = IdCard(val, 2)
      state.ruleForm.birth_date = IdCard(val, 1)
      state.ruleForm.age = IdCard(val, 3)
    }

    const initData = async (item) => {
      state.visible = true
      state.hisSraech = ''
      await getHospitalList()
      formInit()
      await getDict()
      getDoctorList()
      getNurseList()
      if (item && item.id) {
        setList(item)
      } else {
        getPatientCaseNo()
      }
    }
    const formInit = () => {
      state.activeCollNames = ''
      state.ruleForm = {
        hospital_area_id: state.dictList.hospital_area[0].id,
        name: '',
        cert_no: '',
        birth_date: '',
        mobile_phone: '',
        dict_cert: '162110000',
        dict_sex: '101110000',
        dict_nation: '102110000',
        dict_payment_type: '1398728319565856',
        dict_dialysis_cycle: '117130000',
        dict_marry: '315100000',
        dict_patient_source: '105130000',
        dict_blood_type: '104160000',
        dict_rh_blood_type: '109140000',
        dialysis_center_time: TimeYear(new Date()),
        is_center: 1,
        dict_dialysis_type: '210110000',
        cityData: [],
        patient_infect_list: [],
      }
    }

    const getPatientCaseNo = async () => {
      let res = await service.post('/api/patient/get_patient_case_no')
      if (res.code === 0) {
        state.ruleForm.case_no = res.data.case_no
      }
    }

    const setList = (item) => {
      state.ruleForm = item
      if (item.is_open_ganran) {
        state.activeCollNames = '1'
      }
      if (item.province) {
        let province = parseInt(item.province)
        let city = parseInt(item.city)
        state.ruleForm.cityData = [province, city]
        nextTick(() => {
          state.selectCascaderRef.valInt(state.ruleForm.cityData)
        })
      }
      if (item.infect_arr.length > 0) {
        if (!state.dictList[108000000]) return
        item.infect_arr.forEach((obj) => {
          state.dictList[108000000].forEach((item) => {
            if (obj.dict_disease === item.code) {
              item.checked = true
              item.infect_type = obj.infect_type
              if (obj.infect_type === 1) {
                item.date1 = obj.infect_date
              } else if (obj.infect_type === 2) {
                item.date2 = obj.infect_date
              }
            }
          })
        })
      }
      if (item.patient_tag_list.length > 0) {
        if (!state.dictList[323000000]) return
        item.patient_tag_list.forEach((obj) => {
          state.dictList[323000000].forEach((item) => {
            if (obj.code === item.code) {
              item.active = true
            }
          })
        })
      }
    }
    const getGan = async () => {
      let res = await service.post('/api/config/getGan')
      if (res.code === 0) {
        res.data.sub_config.forEach((item) => {
          item.checked = false
          item.infect_type = ''
          item.date1 = ''
          item.date2 = ''
        })
        state.dictList[108000000] = res.data.sub_config
      }
    }
    const getDict = async () => {
      await getGan()
      const dictLists = await gitDictList([
        '162000000',
        '101000000',
        '102000000',
        '103000000',
        '117000000',
        '315000000',
        '105000000',
        '156000000',
        '104000000',
        '109000000',
        '210000000',
        '106000000',
        '390000000',
        '323000000',
      ])
      state.dictList = {
        ...state.dictList,
        ...dictLists,
      }
      state.dictList[323000000].forEach((item) => {
        item.active = false
      })
    }
    const getDoctorList = async () => {
      let res = await service.post('/api/user/list', {
        role_id: 1,
      })
      if (res.code === 0) {
        state.dictList.duty_doctor = res.data.records
      }
    }
    const getNurseList = async () => {
      let res = await service.post('/api/user/list', {
        role_id: 0,
      })
      if (res.code === 0) {
        state.dictList.duty_nurse = res.data.records
      }
    }
    const getHospitalList = async () => {
      let res = await service.post('/api/hospital/list', {
        parent_id: 0,
      })
      if (res.code === 0) {
        state.dictList.hospital_area = res.data
      }
    }

    const inputChang = (val) => {
      state.ruleForm.cityData = val
      state.ruleForm.province = val[0]
      state.ruleForm.city = val[1]
    }
    const InfectedChange = (val) => {
      if (val) {
        state.activeCollNames = '1'
      } else {
        state.activeCollNames = ''
      }
    }

    const infectedTypeChange = (val, item) => {
      if (val || val === 0) {
        item.checked = true
      }
      if (val === 1) {
        item.date1 = TimeYear(new Date())
        item.date2 = ''
      } else if (val === 2) {
        item.date2 = TimeYear(new Date())
        item.date1 = ''
      }
    }

    const infectedTypeRadio = (val, item) => {
      if (val) {
        item.infect_type = 0
      } else {
        item.infect_type = ''
      }
    }

    const remoteMethod = async (query) => {
      if (query) {
        state.loading = true
        let res = await service.post('/api/patient/search_his_patient', {
          keyword: query,
        })
        if (res.code === 0) {
          state.loading = false
          state.hisList = res.data.list
        }
      } else {
        state.hisList = []
      }
    }

    const hisChange = (val) => {
      const row = state.hisList.find((v) => v.his_id == val)
      console.log(row)
      state.ruleForm.hospital_his_id = row.hospital_his_id
      state.ruleForm.menzhen_no = row.menzhen_no
      ElMessageBox.confirm('是否同步患者信息?', 'Warning', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          state.ruleForm.name = row.name
          // state.ruleForm.dict_cert = row.dict_cert
          state.ruleForm.cert_no = row.cert_no
          state.ruleForm.birth_date = row.birth_date
          state.ruleForm.mobile_phone = row.mobile_phone
        })
        .catch(() => {})
    }

    const Unbinding = () => {
      state.hisSraech = ''
      state.ruleForm.hospital_his_id = ''
      state.ruleForm.menzhen_no = ''
    }

    // 文件上传
    const handleRequest = async (param) => {
      let fileObj = param.file
      let form = new FormData()
      form.append('img_file', fileObj)
      form.append('type', 'avatar')
      let res = await service.post('/api/common/upload_img', form)
      if (res.code === 0) {
        state.ruleForm.avatar = requestUrl + res.data.url
      }
    }

    // change取name
    const selectChang = (val, type) => {
      let items = ''
      let name = ''
      switch (type) {
        case 'duty_doctor': {
          items = state.dictList[type]
          name = 'duty_doctor_name'
          break
        }
        case 'duty_nurse': {
          items = state.dictList[type]
          name = 'duty_nurse_name'
          break
        }
        case 'hospital_area': {
          items = state.dictList[type]
          name = 'hospital_area_name'
          break
        }
      }
      items.forEach((item) => {
        if (val === item.id) {
          state.ruleForm[name] = item.name
        }
      })
    }

    const tagBut = (item) => {
      item.active = !item.active
    }

    // 获取选择标签
    const getTabList = () => {
      let tabList = []
      const item = state.dictList[323000000]
      for (let i = 0; i < item.length; i++) {
        if (item[i].active) {
          tabList.push(item[i].code)
        }
      }
      return tabList
    }

    // 获取选择感染病
    const getInfectList = () => {
      let infectList = []
      const item = state.dictList[108000000]
      for (let i = 0; i < item.length; i++) {
        if (item[i].checked) {
          let parient = {}
          parient.dict_disease = item[i].code
          parient.infect_type = item[i].infect_type
          if (item[i].infect_type === 0) {
            parient.infect_date = ''
          } else if (item[i].infect_type === 1) {
            if (!item[i].date1) {
              ElMessage.error(`请选择${item[i].name}新增时间`)
              return false
            }
            parient.infect_date = item[i].date1
          } else {
            if (!item[i].date2) {
              ElMessage.error(`请选择${item[i].name}转阴时间`)
              return false
            }
            parient.infect_date = item[i].date2
          }
          infectList.push(parient)
        }
      }
      return infectList
    }

    const ruleForm = (formEl) => {
      return new Promise((resolve) => {
        state[formEl].validate((valid) => {
          if (valid) {
            resolve()
          }
        })
      })
    }

    const savePatient = () => {
      let checkList = [
        ruleForm('ruleForm1Ref'),
        ruleForm('ruleForm2Ref'),
        ruleForm('ruleForm3Ref'),
      ]
      Promise.all(checkList)
        .then(async () => {
          let infectList = getInfectList()
          if (infectList === false) return
          let tabList = getTabList()
          state.ruleForm.patient_infect_list = infectList
          state.ruleForm.patient_tag_list = tabList
          state.saveLoad = true
          let res = await service.post(
            '/api/patient/save_patient',
            state.ruleForm
          )
          state.saveLoad = false
          if (res.code === 0) {
            ElMessage.success(res.msg)
            state.visible = false
            ctx.emit('refreshDataList')
          }
        })
        .catch(() => {
          return false
        })
    }

    return {
      ...toRefs(state),
      initData,
      handleRequest,
      selectChang,
      savePatient,
      inputChang,
      InfectedChange,
      infectedTypeChange,
      infectedTypeRadio,
      tagBut,
      certNoBlur,
      remoteMethod,
      hisChange,
      Unbinding,
    }
  },
}
</script>

<style scoped lang="scss">
.cont {
  margin-top: 16px;
}
.userItem {
  &:deep(.el-collapse) {
    border: 0;
    .el-collapse-item__header {
      border: 0;
      cursor: default;
      margin-bottom: 16px;
    }
    .el-collapse-item__content {
      padding-bottom: 0;
    }
    .el-collapse-item__arrow {
      width: 0;
    }
  }
}
.cont-left {
  width: 200px;
  background: rgba(49, 102, 174, 0.1);
  border-radius: 16px;
  text-align: center;
  padding: 24px;
  margin-right: 32px;
}
.cont-main {
  flex: 1;
  max-height: 550px;
  overflow-y: auto;
  overflow-x: hidden;
  .el-form-item {
    width: 30%;
    margin-right: 5%;
    &.addr {
      width: 65%;
      margin-right: 0;
    }
    &.his {
      width: 60%;
      margin-right: 0;
    }
    &.hisid {
      width: 500px;
      margin-right: 0;
    }
    &.unbinding {
      width: 120px;
      margin-right: 0;
    }
  }

  .el-form-item:nth-child(3n + 3) {
    margin-right: 0;
  }
  .userItem {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 16px;
    &:last-child {
      border-bottom: 0;
    }
    &.collapse {
      .user-tit {
        margin-bottom: 0;
      }
    }
    .user-tit {
      font-size: 18px;
      line-height: 18px;
      font-family: 'Source Han Sans CN-Medium';
      color: #333333;
      border-left: 6px solid #3166ae;
      padding: 0 18px;
      margin-bottom: 16px;
    }
  }
}
.upload {
  margin-top: 30px;
  .avatar {
    width: 80px;
    height: 80px;
    text-align: center;
    border-radius: 50%;
  }
}
.avatar-uploader-icon {
  background: #c4c4c4;
  width: 80px;
  height: 80px;
  text-align: center;
  border-radius: 50%;
  position: relative;
  text-align: center;
  .tit {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 4px;
    font-size: 14px;
    font-family: 'Source Han Sans CN-Regular';
    font-weight: 400;
    color: #ffffff;
    display: inline-block;
    margin-top: 28px;
    padding: 2px 6px;
  }
}
.infected {
  flex-wrap: wrap;
  width: 103.3%;
  padding-bottom: 14px;
  .infected-item {
    width: 30%;
    margin-right: 3.3%;
    min-width: 370px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    margin-bottom: 10px;
    padding: 10px;
    padding-bottom: 6px;
    .infected-type {
      width: 88px;
      height: 120px;
      background: #488bff;
      border-radius: 8px;
      margin-right: 10px;
      .el-checkbox {
        margin-left: 10px;
      }
    }
    .infected-cont {
      width: 270px;
      &:deep(.el-date-editor) {
        min-width: 140px;
        width: 78%;
      }
      &:deep(.el-radio__label) {
        color: #666666;
      }
      .el-radio {
        margin-bottom: 10px;
        margin-right: 0;
      }
    }
  }
  // .infected-item:nth-child(3n + 3) {
  //   margin-right: 0;
  // }
}
.parient-tag {
  border-radius: 8px 8px 8px 8px;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding-bottom: 14px;
}
</style>

<style>
.nowarp .el-scrollbar__view {
  overflow-x: hidden;
}
</style>
